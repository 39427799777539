import React from "react";
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from '../components/seo';

export default ({ pageContext }) => {

  console.log('pageContext: ', pageContext);


  const getClusterIntros = data => {
    console.log('data index: ', data);
  }

     
  // return (
  //   <div key={blogBody.childMarkdownRemark.id}>
  //     <p>CLUSTER TITLE: <Link to={`/${handle}/`}>{blogTitle}</Link></p>
  //     <p>by: {blogAuthor.name}</p>
  //   </div>
  //   ); 
  // })

  return (
    <Layout>
      <SEO title={"Essential Espresso"} description={"This is the home page"} />
      <h1>Pillar Page:</h1>
      {/* { getClusterIntros(data) } */}
    </Layout> 
  );

}

// export const query = graphql`
//   query {
//     allContentfulBlogPost {
//         edges {
//           node {
//             blogAuthor {
//               authorEmail
//               name
//               authorBio {
//                 authorBio
//               }
//             }
//             blogBody {
//               childMarkdownRemark {
//                 id
//                 html
//               }
//             }
//             blogTitle
//             handle
//             metaKeywords
//             metaTitle
//             metaDescription {
//               metaDescription
//             }
//           }
//         }
//       }
//   }
// `;
